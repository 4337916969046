import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Wallet } from "../components";
import { ThirdwebProvider, useConnectionStatus } from "@thirdweb-dev/react";
import { ZksyncEra } from "@thirdweb-dev/chains";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { getBanks, getExchangeRate, getFees, verifyAccountNumber } from "../api";
import DropDownIcon from "../../static/icons/dropdown.svg";
import LogoIcon from "../../static/icons/logo.svg";

const initialValues = {
	email: "",
	amount: "",
	bank: "",
	accountNumber: "",
	accountName: "",
};

const validationSchema = Yup.object().shape({
	email: Yup.string().email("Please provide a vaid email").required("Your email is required"),
	amount: Yup.number().min(6.5, "Minimum amount required is 6.5 zkUSD").required("Amount is required"),
	bank: Yup.string().required("Destination bank is required"),
	accountNumber: Yup.string()
		.length(10, "Please provide a valid account number")
		.required("Please provide an account number"),
	accountName: Yup.string().required("Invalid account details"),
});

function Withdraw() {
	const [banks, setBanks] = useState([]);
	const [rate, setRate] = useState("");
	const [fees, setFees] = useState({});

	useEffect(() => {
		getBanks().then((res) =>
			setBanks(
				res?.data?.data.map((bank) => ({
					value: bank.code,
					label: bank.name,
				}))
			)
		);
		getExchangeRate().then((res) => setRate(res?.data?.data?.ngn_rate));
		getFees().then((res) => setFees(res?.data?.data));
	}, []);

	return (
		<ThirdwebProvider activeChain={ZksyncEra} supportedChains={[ZksyncEra]}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Withdraw your zkUSD to your bank account | payouts.cash</title>
				<meta name="description" content="Convert your Naira to crypto | Sell your USDT/BTC for Naira ⚡️" />
				<meta
					name="keywords"
					content="payments, crypto, naira, nigeria, remittance, exchange, send, receive, money, convert, transfer"
				/>
				<meta
					property="og:title"
					content="Convert your Naira to crypto | Sell your USDT/BTC for Naira | payouts.cash"
					key="title"
				/>
				<meta property="og:url" content={"payouts.cash"} key="ogurl" />
				<meta
					property="og:description"
					content="Convert your Naira to crypto | Sell your USDT/BTC for Naira ⚡️"
					key="description"
				/>
				<link rel="icon" href="/favicon.ico" />

				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="https://ik.imagekit.io/tryswoosh/payouts_cash_bg_1.jpeg" />
				<meta property="og:site_name" content="payouts.cash" />

				<meta property="og:url" content="payouts.cash" />
				<meta
					property="og:image:secure_url"
					content="https://ik.imagekit.io/tryswoosh/payouts_cash_bg_1.jpeg"
				/>
				<meta property="og:image:alt" content="Crypto to Cash" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@trypayouts" />
				<meta
					name="twitter:title"
					content="Convert your Naira to crypto | Sell your USDT/BTC for Naira | payouts.cash"
				/>
				<meta
					name="twitter:description"
					content="Convert your Naira to crypto | Sell your USDT/BTC for Naira ⚡️"
				/>
				<meta name="twitter:image" content="https://ik.imagekit.io/tryswoosh/payouts_cash_bg_1.jpeg" />

				<meta name="twitter:image:alt" content="payouts.cash SEO Image" />
				<meta property="twitter:url" content="https://payouts.cash" />
			</Helmet>
			<div className="min-h-[100vh] bg-white flex items-center justify-center flex-col px-[15px] gap-[25px]">
				<h1 className="text-primary text-[20px] font-medium text-center min-[500px]:max-w-[350px]">
					Withdraw your zkUSD to your bank account
				</h1>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnMount
					validateOnBlur
					onSubmit={(values) => {
						console.log(values);
					}}
				>
					{({ values, handleChange, setFieldValue, handleSubmit, isValid }) => (
						<form className="max-w-[400px] w-full flex flex-col gap-3" onSubmit={handleSubmit}>
							<Buttons />
							<p className="bg-[#2323230D] w-fit mx-auto text-[#232323] py-[4px] px-[8px] rounded-lg">
								No gas fee required
							</p>
							<p className="bg-[#5835FF1A] text-primary py-[4px] px-[8px] text-center rounded-lg">
								1 zkUSD = {rate} NGN *** Flat fee: {fees?.usdt_fee} USD
							</p>
							<Input
								placeholder="Email address"
								name="email"
								value={values.email}
								onChange={handleChange}
							/>
							<Input
								placeholder="Amount in zkUSD"
								name="amount"
								value={values.amount}
								onChange={handleChange}
							/>
							<Selects
								placeholder="Select Bank"
								options={banks}
								onChange={(selectedOption) => setFieldValue("bank", selectedOption.value)}
								value={banks?.find((bank) => bank.value === values.bank)}
							/>
							<Input
								placeholder="Account Number"
								name="accountNumber"
								value={values.accountNumber}
								onChange={handleChange}
							/>
							<AccountName />
							{isValid && <Wallet.TransferButton />}
						</form>
					)}
				</Formik>
				<p className="flex items-end gap-1 text-[#232323] text-[14px] leading-[20px]">
					Withdraw other crypto on
					<a className="mb-[0.2rem]" href="/" target="_blank">
						<LogoIcon />
					</a>
				</p>
			</div>
		</ThirdwebProvider>
	);
}

function Buttons() {
	const connectionStatus = useConnectionStatus();

	// console.log(connectionStatus);

	return (
		<div className="grid grid-cols-2 gap-3">
			<Wallet.ConnectWalletButton />
			{connectionStatus === "connected" && <Wallet.Balance />}
		</div>
	);
}

function Input(props) {
	const { errors, touched, handleBlur } = useFormikContext();

	return (
		<div>
			<input
				type="text"
				onBlur={handleBlur}
				className={`px-[24px] py-[10px] border ${
					!!touched[props.name] && !!errors[props.name] ? "border-red-500" : "border-[#23232399]"
				} rounded-lg text-[16px] leading-[24px] text-[#232323d9] placeholder:text-[16px] placeholder:leading-[24px] placeholder:text-[#23232366] w-full`}
				{...props}
			/>
			<ErrorMessage name={props.name} component="div" className="text-red-500 text-[0.75rem]" />
		</div>
	);
}

function Selects(props) {
	const { errors, touched, setFieldTouched } = useFormikContext();

	return (
		<div>
			<Select
				isSearchable
				onBlur={() => setFieldTouched("bank", true)}
				components={{
					DropdownIndicator: DropDownIcon,
				}}
				styles={{
					container: (baseStyles) => ({
						...baseStyles,
						flex: 1,
					}),
					control: (baseStyles) => ({
						...baseStyles,
						border: !!touched.bank && !!errors.bank ? "1px solid #ef4444" : "1px solid #23232399",
						borderRadius: "8px",
					}),
					placeholder: (baseStyles) => ({
						...baseStyles,
						fontSize: "16px",
						fontWeight: 400,
						lineHeight: "24px",
						color: "#23232366",
					}),
					valueContainer: (baseStyles) => ({
						...baseStyles,
						padding: "9px 0 9px 24px",
					}),
					singleValue: (baseStyles) => ({
						...baseStyles,
						fontSize: "16px",
						fontWeight: 400,
						lineHeight: "24px",
						color: "#232323d9",
					}),
					input: (baseStyles) => ({
						...baseStyles,
						padding: 0,
					}),
					indicatorSeparator: (baseStyles) => ({
						...baseStyles,
						width: 0,
						border: "none",
					}),
					indicatorsContainer: (baseStyles) => ({
						...baseStyles,
						padding: "0 24px 0 0",
					}),
					dropdownIndicator: (baseStyles) => ({
						...baseStyles,
						padding: 0,
					}),
				}}
				{...props}
			/>
			<ErrorMessage name="bank" component="div" className="text-red-500 text-[0.75rem]" />
		</div>
	);
}

const AccountName = () => {
	const { values, handleChange, setFieldValue, errors, touched, setFieldTouched, handleBlur } = useFormikContext();

	useEffect(() => {
		if (!!values.accountNumber && values.accountNumber.length === 10 && !!values.bank) {
			verifyAccountNumber({
				account_number: values.accountNumber,
				bank_code: values.bank,
				currency: "NGN",
			})
				.then((data) => {
					setFieldValue("accountName", data?.data?.data?.account_name, true);
				})
				.catch(() => {
					setFieldValue("accountName", "", false);
					setFieldTouched("accountName", true);
				});
		}
	}, [setFieldValue, values.accountNumber, values.bank, setFieldTouched]);

	if (!!values.accountNumber && values.accountNumber.length === 10 && !!values.bank)
		return (
			<div>
				<input
					type="text"
					className={`px-[24px] py-[10px] border ${
						!!touched.accountName && !!errors.accountName
							? "disabled:border-red-500"
							: "disabled:border-[#EEEBFF]"
					} rounded-lg text-[16px] leading-[24px] text-[#232323d9] placeholder:text-[16px] placeholder:leading-[24px] placeholder:text-[#23232366] disabled:bg-[#EEEBFF]  w-full`}
					name="accountName"
					placeholder="Account Name"
					value={values.accountName}
					onChange={handleChange}
					onBlur={handleBlur}
					disabled
				/>
				<ErrorMessage name="accountName" component="div" className="text-red-500 text-[0.75rem]" />
			</div>
		);
};

export default Withdraw;
